<script>
import { computed, inject, onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import ProjectSearchFilter from "./search-filter/ProjectSearchFilter.vue";
import allProjectNavIcon from "../../assets/vue-icons/all-project-nav-icon.vue";
import projectCategoryIcon from "../../assets/vue-icons/search-category-icon/project-category-icon.vue";
import actionButton from "../common/ActionButton.vue";
import documentTextCategoryIcon from "../../assets/vue-icons/search-category-icon/document-text-category-icon.vue";
import fileCategoryIcon from "../../assets/vue-icons/search-category-icon/file-category-icon.vue";
import highlightAnnoCategoryIcon from "../../assets/vue-icons/search-category-icon/highlight-anno-category-icon.vue";
import textAnnoCategoryIcon from "../../assets/vue-icons/search-category-icon/text-anno-category-icon.vue";
import emptySearchResultIcon from "../../assets/vue-icons/empty-search-result-icon.vue";
import { mutations, getters } from "../../store/index.js";
import { BROADCAST_MESSAGE, ERROR_TOAST } from "../../core/constants.js";
import leftSidePanelState from "../../store/left-side-panel-state.js";

export default {
  name: "search-field",
  components: {
    ProjectSearchFilter,
    allProjectNavIcon,
    emptySearchResultIcon,
    projectCategoryIcon,
    actionButton,
    fileCategoryIcon,
    documentTextCategoryIcon,
    highlightAnnoCategoryIcon,
    textAnnoCategoryIcon,
  },
  emits: ["searchShown"],
  setup() {
    const $fetch = inject("$fetch");
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);
    const isResultArraysEmpty = ref(false);
    const unsubscribe = reactive({ fn: null });
    const searchInput = ref(null);
    const activeFocusSearch = ref(false);
    const searchQueries = ref(JSON.parse(localStorage.getItem('recentSearchQueries')) || []);
    const isGlobalSearchShown = computed(() => {
      return getters.getGlobalSearchStatus()
    })
    const isFocusSearch = ref(false);
    const queryInput = ref("");
    const titles = {
      ProjectSearchResult: "Projects",
      DocumentSearchResult: "Documents",
      DocumentTextSearchResult: "Document text",
      DocumentObjectSearchResult: "Annotations",
    };
    const fromProject = ref(null);
    watch(queryInput, (value) => {
      if (!value) {
        clearSearchResults()
      }
    })
    watch(isFocusSearch, (value) => {
      if (!value) {
        mutations.changeGlobalSearchStatus(false)
      }
    })
    watch(isGlobalSearchShown, (isGlobalSearchValue) => {
      if (isGlobalSearchValue) {
        isFocusSearch.value = isGlobalSearchValue
        queryInput.value = ''
        searchInput.value.focus();
      }
    })
    const result = reactive({
      ProjectSearchResult: [],
      DocumentSearchResult: [],
      DocumentTextSearchResult: [],
      DocumentObjectSearchResult: [],
    });
    onMounted(() => {
      searchQueries.value = JSON.parse(localStorage.getItem('recentSearchQueries')) || [];
    })
    function checkResults(keyCategory) {
      return result[keyCategory].length > 3;
    }
    function searchChunk(messageData = {}) {
      const { data: { search } } = messageData
      mutations.addSearchResults(search.__typename, search)
      result[search.__typename].push(search)
    }
    function validationSearchValue() {
      let type = ERROR_TOAST;
      let title = '';
      let message = '';
      let toast0 = {}
      if (!queryInput.value || queryInput.value.trim() === '' || queryInput.value.length === 0) {
        title = 'Error Search';
        message = "Please enter a search value"
        toast0 = {
          type, title, message,
        }
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, toast0)
        return false
      }
      return true
    }
    function gotoProject(item) {
      isFocusSearch.value = false;
      router.push(`/p/${item.project.id}`);
    }
    function gotoDocumentObject(item) {
      isFocusSearch.value = false;
      router.push(`/p/${item.project.id}/d/${item.document.id}?page=${item.documentObject.page + 1}&object=${item.documentObject.id}`);
    }
    function searchRecentQueries(recentSearchValue) {
      queryInput.value = recentSearchValue;
      submit();
    }
    function gotoDocument(item) {
      isFocusSearch.value = false;
      router.push(`/p/${item.project.id}/d/${item.document.id}`);
    }
    function gotoDocumentText(item) {
      isFocusSearch.value = false;
      router.push(`/p/${item.project.id}/d/${item.document.id}?page=${item.page + 1}`);
    }

    const searchCategories = computed(() => {
      const categories = Object.keys(result).map(key => {
        return { key, title: titles[key] };
      });
      return categories.filter(category => {
        return result[category.key].length > 0;
      });
    });
    function searchError(error) {
      $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
        type: ERROR_TOAST,
        title: "Search error",
        message: error.message
      })
      unsubscribe.fn = null;
      isLoading.value = false;
    }
    function searchCompleted() {
      unsubscribe.fn = null;
      isLoading.value = false;
      checkResultsLength()
    }
    function checkResultsLength() {
      let isEmpty = true;
      for (const prop in result) {
        if (result[prop].length !== 0) {
          isEmpty = false;
          break;
        }
      }
      isResultArraysEmpty.value = isEmpty
    }
    function closeSearch() {
      isFocusSearch.value = false;
      queryInput.value = '';
      activeFocusSearch.value = false;
      clearSearchResults()
    }
    function clearSearchResults() {
      result.ProjectSearchResult = [];
      result.DocumentSearchResult = [];
      result.DocumentObjectSearchResult = [];
      result.DocumentTextSearchResult = [];
    }
    function viewAllResult() {
      isFocusSearch.value = false;
      router.push({
        name: 'global-search',
        query: { queryString: queryInput.value },
      });
    }
    function saveSearchQuery() {
      searchQueries.value.unshift(queryInput.value);
      searchQueries.value = searchQueries.value.slice(0, 3);
      localStorage.setItem('recentSearchQueries', JSON.stringify(searchQueries.value));
    }
    async function submit() {
      if (route.name !== "global-search") {
        leftSidePanelState.setFromProject(route.params["projectId"] || null);
      }
      const isValidCredentials = validationSearchValue()
      if (!isValidCredentials) return
      saveSearchQuery();
      viewAllResult()
    }

    return {
      queryInput, submit, closeSearch, result,
      isFocusSearch, searchCategories, gotoProject,
      gotoDocument, gotoDocumentText, searchQueries, activeFocusSearch,
      searchRecentQueries, isLoading, gotoDocumentObject,
      checkResults, isResultArraysEmpty, viewAllResult, searchInput,
    }
  }
}
</script>

<template>
  <div class="dark-background" v-if="isFocusSearch" @click="isFocusSearch = false; activeFocusSearch = false;" />

  <div class="search-wrapper  min-w-[250px] md:min-w-[500px] left-0 md:left-auto" :class="{ 
    'focus-search-wrapper w-[375px] md:max-w-[500px]': isFocusSearch }">
    <div class="search-field"
      :class="{ 
        'focus-search-field': activeFocusSearch, 'max-md:visible max-w-96 md:w-[580px]': activeFocusSearch, 
      'max-md:invisible w-[350px] md:w-[580px]': !activeFocusSearch 
      }"
      >
      <div class="search-icon"></div>
      <input class="search-input" ref="searchInput" type="text" placeholder="Search" v-model="queryInput"
        @keydown.enter.stop="submit"
        @focus="queryInput ? isFocusSearch = false : isFocusSearch = true; activeFocusSearch = true"
        @blur="activeFocusSearch = false;">
      <div class="close-icon" v-if="queryInput" @click="closeSearch();"></div>
    </div>
    <div class="invisible max-md:visible absolute right-42 flex" @click="isFocusSearch = true; activeFocusSearch = true"
    :class="{ 'max-md:invisible': activeFocusSearch}"
    >
      <svg width="48" height="52" viewBox="0 0 48 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.9588 30.9176C25.8021 30.9176 28.9176 27.8021 28.9176 23.9588C28.9176 20.1156 25.8021 17 21.9588 17C18.1156 17 15 20.1156 15 23.9588C15 27.8021 18.1156 30.9176 21.9588 30.9176Z"
          stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M26.8794 28.8794L32.5 34.5" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
      <svg class="mr-4" width="20" height="52" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 4V28" stroke="#E2E8F0" />
      </svg>

    </div>
    <div class="search-result" v-if="isFocusSearch">
      <div class="empty-search-view" v-if="searchQueries.length === 0">
        <div class="empty-search-logo">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.0003 7.91602C13.3269 7.91602 7.91699 13.3259 7.91699 19.9993C7.91699 26.6728 13.3269 32.0827 20.0003 32.0827C17.9293 32.0827 14.5837 27.4993 14.5837 19.9993M20.0003 7.91602C26.6738 7.91602 32.0837 13.3259 32.0837 19.9993M20.0003 7.91602C17.9293 7.91602 14.5837 12.4993 14.5837 19.9993M20.0003 7.91602C21.5891 7.91602 23.9282 10.6135 24.9312 15.2561C24.9457 15.3229 24.9598 15.3902 24.9737 15.4579M14.5837 19.9993H8.33366M14.5837 19.9993H18.7503"
              stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M29.5343 25.3922C29.5343 27.6798 27.6798 29.5343 25.3922 29.5343C23.1045 29.5343 21.25 27.6798 21.25 25.3922C21.25 23.1045 23.1045 21.25 25.3922 21.25C27.6798 21.25 29.5343 23.1045 29.5343 25.3922Z"
              fill="#FCDCD3" />
            <path
              d="M28.5784 28.5784L32.0833 32.0833M25.3922 29.5343C27.6798 29.5343 29.5343 27.6798 29.5343 25.3922C29.5343 23.1045 27.6798 21.25 25.3922 21.25C23.1045 21.25 21.25 23.1045 21.25 25.3922C21.25 27.6798 23.1045 29.5343 25.3922 29.5343Z"
              stroke="#F04E23" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <span class="empty-search-text">
          Search Folia for projects, documents, and annotations by typing a search query and hitting ↵ Return on your
          keyboard.
        </span>
      </div>
      <div class="recent-search-container" v-if="searchQueries.length > 0 && !queryInput && !isLoading">
        <div class="recent-search-title">Recent Searches</div>
        <div class="recent-search-result">
          <div class="recent-search-item" v-for="recentQueries in searchQueries"
            @click.stop="searchRecentQueries(recentQueries)">
            <div class="clock-icon">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.00033 3.33268V5.99935L7.33366 7.33268M10.8337 5.99935C10.8337 8.66872 8.6697 10.8327 6.00033 10.8327C3.33095 10.8327 1.16699 8.66872 1.16699 5.99935C1.16699 3.32997 3.33095 1.16602 6.00033 1.16602C8.6697 1.16602 10.8337 3.32997 10.8337 5.99935Z"
                  stroke="#334155" stroke-width="1.5" stroke-linecap="round" />
              </svg>
            </div>
            <span class="recent-search-text">{{ recentQueries }}</span>
          </div>
        </div>
      </div>
      <div class="loading-spinner" v-if="isLoading" />
    </div>
  </div>

</template>

<style scoped lang="scss">
.dark-background {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-wrapper {
  // position: absolute;
  display: flex;
  padding: 16px;
  align-items: center;
  width: 98%;
  // min-width: 250px;
  // margin-left: 20%;

  .search-field {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 80%;
    height: 36px;
    padding: 8px 12px 8px 12px;
    background: #F1F5F9;
    border-radius: 100px;

    .search-icon {
      color: #94A3B8;
      width: 14px;
      height: 14px;
      background-image: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.0413 13.0418L9.91634 9.91683M0.958008 6.16683C0.958008 3.29035 3.28986 0.958496 6.16634 0.958496C9.04282 0.958496 11.3747 3.29035 11.3747 6.16683C11.3747 9.04331 9.04282 11.3752 6.16634 11.3752C3.28986 11.3752 0.958008 9.04331 0.958008 6.16683Z' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
    }

    .search-input {
      border: none;
      outline: none;
      background: transparent;
      flex-grow: 1;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #334155;

      &::placeholder {
        color: #94A3B8;
      }
    }

    .close-icon {
      cursor: pointer;
      width: 14px;
      height: 14px;
      background-image: url("data:image/svg+xml, %3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.625 3.375L3.375 8.625M3.375 3.375L8.625 8.625' stroke='%23334155' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
    }

    .search-field-button {
      border: none;
      outline: none;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .focus-search-field {
    border: 1px solid #2990FF;

    .search-icon {
      color: #2990FF;
    }
  }

  .search-result {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: start;

    .empty-search-view {
      display: flex;
      margin: 16px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .empty-search-text {
        margin-top: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #64748B;
      }
    }

    .search-filter-container {
      color: #334155;
      height: 44px;
      margin-bottom: 14px;

      .search-filter-title {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }

      .search-filter-select-container {
        height: 34px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }

    .divider-line {
      width: 100%;
      margin-top: 8px;
      margin-bottom: 8px;
      border: 1px solid #E2E8F0;
    }

    .recent-search-container {
      max-height: 175px;
      display: flex;
      flex-direction: column;

      .recent-search-title {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #64748B;
      }

      .recent-search-result {
        margin-top: 14px;
        margin-left: 8px;

        .recent-search-item {
          font-weight: 400;
          font-size: 12px;
          padding: 6px 8px;
          line-height: 16px;
          color: #334155;
          display: flex;
          cursor: pointer;
          align-items: center;

          &:hover {
            background: #F8FAFC;
            border-radius: 6px;
          }

          .recent-search-text {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 250px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #334155;
          }

          .clock-icon {
            width: 16px;
            height: 16px;
            padding-top: 2px;
            margin-right: 4px;
          }
        }
      }

    }

    .loading-spinner {
      display: flex;
      align-items: center;
      width: 100%;
      height: 45px;
      background-color: white;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/loading-icon.gif");
      background-position: center;
      border-radius: 5px;
    }

    .search-query-result {
      overflow: auto;
      max-height: 616px;

      .title-result {
        font-weight: 600;
        font-size: 14px;
        margin-top: 16px;
        margin-bottom: 16px;
        line-height: 20px;
        color: #334155;
      }

      .empty-state-results {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 200px;
        margin-bottom: 10px;

        .empty-state-title {
          margin-top: 32px;
          margin-bottom: 32px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #334155;
        }

        .clear-results-btn {
          border: 1px solid #E2E8F0;
          border-radius: 8px;
        }
      }

      .search-category {
        margin: 5px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .category-title {
          display: inline-block;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #6e737b;
        }

        .search-category-result {
          width: 100%;

          ul {
            padding: 0;
            margin: 12px 0 12px;
            cursor: pointer;
            list-style-type: none;

            .project-item {
              display: flex;
              align-items: center;
              padding: 4px 8px;
              text-align: center;

              &:hover {
                background: #F8FAFC;
                border-radius: 6px;
              }

              .project-title {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #334155;
              }
            }

            .document-item {
              display: flex;
              width: 100%;
              padding: 4px 8px;
              align-items: center;
              margin-top: -15px;
              text-align: center;

              &:hover {
                background: #F8FAFC;
                border-radius: 6px;
              }

              .text-document-item {
                display: flex;
                text-align: start;
                flex-direction: column;

                .document-title {
                  margin-top: 15px;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #334155;
                }

                .project-title {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #64748B;
                }
              }
            }

            .inside-document-text-item {
              display: flex;
              align-items: center;
              margin-top: -15px;
              padding: 4px 8px;
              text-align: center;

              &:hover {
                background: #F8FAFC;
                border-radius: 6px;
              }

              .inside-document-text-container {
                display: flex;
                text-align: start;
                flex-direction: column;

                .document-inside-text-title {
                  padding-top: 15px;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #334155;
                }

                .document-title {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #64748B;
                }
              }
            }

            .annotations-item {
              display: flex;
              align-items: center;
              margin-top: -15px;
              padding: 4px 8px;
              text-align: center;

              &:hover {
                background: #F8FAFC;
                border-radius: 6px;
              }

              .annotations-item-container {
                display: flex;
                text-align: start;
                flex-direction: column;

                .annotations-item-title {
                  padding-top: 15px;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #334155;
                }

                .document-title {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #64748B;
                }
              }
            }
          }
        }

        .all-results-title {
          cursor: pointer;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #94A3B8;
        }
      }
    }
  }
}

.focus-search-wrapper {
  position: absolute;
  // width: 612px;
  z-index: 100;
  flex-direction: column;
  top: 0;
  border: 1px solid rgba(51, 65, 85, 0.15);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #FFFFFF;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    left: 150px;
}
  .search-field {
    width: 580px;
  }
}

.app-container {
  @media only screen and (max-width: 768px) {
    & .search-wrapper {
      // min-width: 200px;
    }
  }
}
</style>
