<script>
import showSidebarIcon from "../../assets/vue-icons/show-sidebar-icon.vue";
import historyIcon from "../../assets/vue-icons/history-icon.vue";
import Avatar from "../common/Avatar.vue";
import UserInfoModal from "./UserInfoModal.vue";
import leftMenuIcon from "../../assets/vue-icons/left-menu-icon.vue";
import { useRoute, useRouter } from "vue-router";
import { ref, inject, onMounted, onBeforeUnmount, watch, computed } from "vue";
import { BROADCAST_MESSAGE, OFFLINE_TOAST, SYNCING_STATUS } from "../../core/constants.js";
import SearchField from "./SearchField.vue";
import { mutations, getters } from "../../store/index.js";
// import leftPanelState from "../../store/left-side-panel-state.js";
import SyncIndicator from "./SyncIndicator.vue";
import ProfileService from "../../service/profileService";

export default {
  components: { SyncIndicator, SearchField, showSidebarIcon, historyIcon, UserInfoModal, Avatar, leftMenuIcon },
  props: ["leftPanelCanBeOpened", "isPanelPinned"],
  emits: ["pinLeftSidePanel"],
  setup() {
    const router = useRouter();
    const route = useRoute();
    const showUserModal = ref(false);
    const ownerTitle = ref("");
    const $fetch = inject("$fetch")
    const $auth0 = inject("$auth0")
    const syncStatusTimerId = ref(null);
    const isOffline = ref(false);
    const syncStatus = ref("DONE");
    let breakSyncing = false;
    let every10secSyncTimer = null

    const displaySyncStatus = computed(() => {
      if (isOffline.value) return "OFFLINE";
      if (syncStatus.value === SYNCING_STATUS.FAILED) {
        return "FAILED";
      } else if (syncStatus.value === SYNCING_STATUS.WORKING) {
        return "SYNCING";
      } else {
        return "DONE";
      }
    });
    const profileService = new ProfileService()
    onMounted(() => {
      breakSyncing = false;
      $fetch.on(BROADCAST_MESSAGE.OWNER_UPDATED, fetchOwner);
      $fetch.on(BROADCAST_MESSAGE.SYNC_STATUS, syncStatusWatcher);
      window.addEventListener("online", onlineStatusListener);
      window.addEventListener("offline", offlineStatusListener);
      fetchOwner()
        .then(() => every10secSync())
        .catch(console.error);
    });
    onBeforeUnmount(() => {
      breakSyncing = true;
      clearTimeout(syncStatusTimerId.value);
      clearTimeout(every10secSyncTimer);
      $fetch.off(BROADCAST_MESSAGE.OWNER_UPDATED, fetchOwner);
      $fetch.off(BROADCAST_MESSAGE.SYNC_STATUS, syncStatusWatcher);
      window.removeEventListener("online", onlineStatusListener);
      window.removeEventListener("offline", offlineStatusListener);
    });
    watch(() => getters.isAuthenticated, async (value) => {
      await fetchOwner();
    });
    function offlineStatusListener(e) {
      isOffline.value = true;
      $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
        type: OFFLINE_TOAST,
        close_timeout: 30_000,
        title: "You are now offline",
        message: "You are no longer connected to Folia servers, any changes you make might not be saved. ",
      });
    }
    function onlineStatusListener(e) {
      isOffline.value = false;
      every10secSync(true);
    }
    function redirectToHelpPage() {
      window.open('https://help.folia.com/knowledge', '_blank');
    }
    function syncStatusWatcher(status) {
      if (isOffline.value) return;
      syncStatus.value = status;
    }
    async function fetchOwner() {
      // await $fetch.getUserProfile().then((data) => {
      //   ownerTitle.value = data?.name || data?.email || "?"
      // })
      ownerTitle.value = localStorage.getItem('email') || '';
      const { name = null, email = '', profilePicture = '' } = await profileService.getMyProfile();
      ownerTitle.value = name || email || '?';
    }
    function openUserModal() {
      $auth0.getUser().then(user => {
        if (!user.isAuthenticated) {
          return $auth0.signIn();
        }
        showUserModal.value = !showUserModal.value;
      });
    }
    function changeSideBarStatus() {
      mutations.changeSideBarStatus()
    }
    function goBack() {
      if (router.options.history.state.back) {
        router.back();
      }
      return false
    }
    function goForward() {
      router.forward();
    }
    function every10secSync(force = false) {
      // if (breakSyncing) return Promise.resolve();
      // syncStatus.value = SYNCING_STATUS.WORKING;
      // const { projectId, documentId } = route.params;

      // clearTimeout(every10secSyncTimer);
      // // const startPromise = force ? $fetch.outputSync() : Promise.resolve();
      // return $fetch.outputSync().then(() => {
      //   if (!projectId && !documentId) {
      //     return $fetch.inputSyncProjects();
      //   } else if (projectId && !documentId) {
      //     return $fetch.inputSyncProject(projectId);
      //   } else if (projectId && documentId) {
      //     return $fetch.inputSyncDocument(projectId, documentId);
      //   }
      // }).then(() => syncStatus.value = SYNCING_STATUS.IDLE)
      //     .catch(() => syncStatus.value = SYNCING_STATUS.FAILED)
      //     .finally(() => {
      //       every10secSyncTimer = setTimeout(() => every10secSync(), 10_000);
      //     });
    }
    async function sync() {
      if (syncStatus.value !== SYNCING_STATUS.IDLE) return;
      clearTimeout(every10secSyncTimer);
      await every10secSync(true);
    }

    return {
      showUserModal, ownerTitle, displaySyncStatus,

      redirectToHelpPage, openUserModal, goBack, goForward, changeSideBarStatus, sync
    };
  },
};
</script>

<template>
  <div class="relative flex max-w-[100vw] md:w-full items-center">
    <div class="flex items-center">
      <div v-if="!isPanelPinned" class="logo min-w-24 min-h-8" @click="$router.push({ name: 'home' })"></div>
      <folia-button class="" v-if="leftPanelCanBeOpened && !isPanelPinned" size="28" icon="open_side_panel"
        tooltip="Lock Left Panel" @click.stop="$emit('pinLeftSidePanel')"></folia-button>
      <div class="app-bar-nav-routes flex items-center">
        <folia-button size="28" icon="nav_previous" :disabled="!$router.options.history.state.back"
          @click.stop="goBack"></folia-button>
        <folia-button size="28" icon="nav_forward" :disabled="!$router.options.history.state.forward"
          @click.stop="goForward"></folia-button>
      </div>
    </div>

    <div class="flex-grow flex justify-center">
      <div class="w-full max-w-[200px] md:max-w-[600px]">
        <SearchField />
      </div>
    </div>

    <div class="absolute flex right-0 items-center">
      <div class="flex items-center">
        <folia-button class="mr-3.5" size="28" icon="help" @click.stop="redirectToHelpPage()"></folia-button>
        <div class="app-bar-profile app-bar-right-button left-border" @click="openUserModal">
          <Avatar :owner="ownerTitle" class="user-avatar" />
        </div>
        <UserInfoModal @hide="showUserModal = false" v-if="showUserModal" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.logo {
  width: 100px;
  // height: 48px;
  cursor: pointer;
  background-image: url("../../assets/images/logo.svg");
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 56px 28px;
  margin-left: 16px;
}

.app-bar-nav-routes {
  display: flex;
  align-items: center;
  gap: 10px;
}

.app-bar-expander {
  flex-grow: 1;
}

.app-bar-right-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 32px;
  cursor: pointer;

  &.left-border {
    border-left: solid 1px #E2E8F0;
  }
}

.app-bar-profile {
  .user-avatar {
    width: 30px;
    height: 30px;
    background-color: #8cb3d5;
    color: white;
    border-radius: 15px;
    padding: 5px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border: 2px solid #e2e8f0;
  }
}

.app-bar-container {
  position: relative;
  width: 100%;
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  user-select: none;
  background: white;
  padding-right: 10px;

  @media only screen and (max-width: 768px) {
    width: 97%;
  }
}
</style>
