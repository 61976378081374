<script>
import { nextTick, onBeforeUnmount, onMounted, ref } from "vue";
import {openingDocument} from "../../store/open-document.js";

export default {
  name: "search-within-document",
  props: {
    pdfViewer: Object,
  },
  setup(props, {emit}) {
    const inputEl = ref(null);
    const isExpanded = ref(false);
    const searchHasBeenStarted = ref(false);
    const current = ref(0);
    const total = ref(0);
    const query = ref("");
    let searchTimeoutTimer = null;

    onMounted(() => {
      props.pdfViewer.eventBus.on("updatefindmatchescount", updateFindControlState);
      props.pdfViewer.eventBus.on("updatefindcontrolstate", updateFindControlState);
      props.pdfViewer.eventBus.on("open-search-bar", openSearchBar);
      applyOpenDocClarifications();
    })

    onBeforeUnmount(() => {
      props.pdfViewer.eventBus.off("open-search-bar", openSearchBar);
      props.pdfViewer.eventBus.off("updatefindmatchescount", updateFindControlState);
      props.pdfViewer.eventBus.off("updatefindcontrolstate", updateFindControlState);
    })

    function applyOpenDocClarifications() {
      const {page, liveSearchQuery} = openingDocument.clarifications
      props.pdfViewer.closeSearch();
      if (page) {
        props.pdfViewer.page = page;
      }
      if (liveSearchQuery) {
        nextTick().then(() => {
          isExpanded.value = true;
          query.value = liveSearchQuery;
          props.pdfViewer.search(liveSearchQuery);
          openingDocument.resetClarification();
          searchHasBeenStarted.value = true;
          setTimeout(() => inputEl.value.focus(), 500);
        });
      }
    }

    function updateFindControlState({matchesCount}) {
      current.value = matchesCount.current;
      total.value = matchesCount.total;
    }

    function openSearchBar() {
      isExpanded.value = true;
        searchHasBeenStarted.value = false;
      query.value = "";
      current.value = 0;
      total.value = 0;
      nextTick().then(() => inputEl.value.focus());
    }

    function toggleExpand() {
      isExpanded.value = !isExpanded.value;
      if (isExpanded.value === false) {
        query.value = "";
        props.pdfViewer.closeSearch();
      } else {
        searchHasBeenStarted.value = false;
        query.value = "";
        current.value = 0;
        total.value = 0;
        nextTick().then(() => inputEl.value.focus());
      }
    }

    function onInput() {
      searchHasBeenStarted.value = true;
      clearTimeout(searchTimeoutTimer);
      if (query.value?.length >= 3) {
        searchTimeoutTimer = setTimeout(() => {
          props.pdfViewer.closeSearch();
          props.pdfViewer.search(query.value);
        }, 250);
      } else {
        props.pdfViewer.closeSearch();
        nextTick().then(() => {
          current.value = 0;
          total.value = 0;
        });
      }
    }

    function onKeyDown(e) {
      const {key, shiftKey, ctrlKey, metaKey} = e;
      if (["Enter", "Escape"].includes(key)) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (["f", "F"].includes(key) && (ctrlKey || metaKey)) {
        e.preventDefault();
        e.stopPropagation();
      } else if (key === "Enter" && !shiftKey) {
        clearTimeout(searchTimeoutTimer);
        props.pdfViewer.closeSearch();
        if (query.value?.length > 0) {
          props.pdfViewer.search(query.value);
          setTimeout(() => { props.pdfViewer.searchNext(); }, 333);
        }
      } else if (key === "Enter" && shiftKey) {
        clearTimeout(searchTimeoutTimer);
        props.pdfViewer.closeSearch();
        if (query.value?.length > 0) {
          props.pdfViewer.search(query.value);
          setTimeout(() => { props.pdfViewer.searchPrev(); }, 333);
        }
      } else if (key === "Escape") {
        clearTimeout(searchTimeoutTimer);
        isExpanded.value = false;
        query.value = "";
        props.pdfViewer.closeSearch();
      }
    }

    function goNext() {
      props.pdfViewer.searchNext();
    }

    function goPrev() {
      props.pdfViewer.searchPrev();
    }

    return {
      inputEl,
      isExpanded, searchHasBeenStarted, query, current, total,
      toggleExpand, onInput, goNext, goPrev,
      onKeyDown,
    }
  }
};
</script>

<template>
  <div class="document-search-opener" v-if="!isExpanded" @click="toggleExpand">
    <div class="document-search-nav-btn" v-if="!isExpanded">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.0423 13.0423L9.91732 9.91732M0.958984 6.16732C0.958984 3.29083 3.29083 0.958984 6.16732 0.958984C9.0438 0.958984 11.3757 3.29083 11.3757 6.16732C11.3757 9.0438 9.0438 11.3757 6.16732 11.3757C3.29083 11.3757 0.958984 9.0438 0.958984 6.16732Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
  <div class="document-search" v-else>
    <div class="document-search-input-box">
      <input
        ref="inputEl"
        type="text"
        placeholder="Find in this document..."
        class="document-search-input"
        v-model="query"
        @input="onInput"
        @keydown.stop="onKeyDown"
        tabindex="1"
      >
      <span class="document-search-found" v-if="searchHasBeenStarted">{{current}} of {{total}}</span>
    </div>

    <button class="document-search-nav-btn" tabindex="3" v-if="total > 0" @click="goPrev()">
      <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.25 4.25L4 0.75L0.75 4.25" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
    <button class="document-search-nav-btn" tabindex="2" v-if="total > 0" @click="goNext()">
      <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.25 0.75L4 4.25L0.75 0.75" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>

    <button class="document-search-nav-btn" tabindex="4" @click="toggleExpand">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#334155"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
    </button>
  </div>
</template>

<style scoped lang="scss">
.document-search-opener {
  position: absolute;
  top: 16px;
  right: 34px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  background: #FFFFFF;
  border: 1px solid #E2E8F0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
}
.document-search {
  position: absolute;
  top: 16px;
  right: 34px;
  min-width: 338px;
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #E2E8F0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  gap: 8px;

  transition: width 0.25s ease-in-out;

  .document-search-input-box {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 6px;

    .document-search-input {
      width: 100%;
      height: 36px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #334155;
      padding-left: 38px;
      padding-right: 40px;

      border: 1px solid #2990FF;
      border-radius: 6px;
      background-color: white;
      background-image: url("../../assets/images/blue-search.svg");
      background-position: 10px center;
      background-repeat: no-repeat;

      &:focus {
        outline-color: #2990FF;
      }
      &::placeholder {
        color: #d8d8d8;
      }
    }
    .document-search-found {
      position: absolute;
      right: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #94A3B8;
    }
  }

  .document-search-nav-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: none;
    padding: 0;
    outline-color: #CBD5E1;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background-color: #F8FAFC;
    }

    &:active {
      transform: translate(0.5px, 0.5px);
    }
  }
}

.expanded {
  width: 250px;
}
</style>